export const LIST_ALL_HOTEL_OTAS = `query ($pageSize: Int, $pageNumber: Int, $isForDropdown: Boolean) {
  list_all_hotel_otas(page_size: $pageSize, page_number: $pageNumber, is_for_dropdown: $isForDropdown){
      message
      data {
        _id
        ota
        hotel_code
        is_active
        last_sync
        sync_status
      }
      hasMore
    }
}`;

export const ACTIVATE_HOTEL_OTAS = `mutation ($id: String!) {
  activate_hotel_otas(_id: $id){
    message
  }
}`;

export const SYNC_HOTEL_OTAS = `mutation ($id: String!) {
  sync_hotel_otas(_id: $id){
    message
  }
}`;

export const LIST_ALL_OTA_RATEPLANS = `query ($pageSize: Int, $pageNumber: Int, $roomtypeId: String!) {
  list_all_ota_rateplans(page_size: $pageSize, page_number: $pageNumber, roomtype_id: $roomtypeId){
    message
    data {
      _id
      ota
      rate_code
      is_active
      meal_plan_id {
        _id
        name
      }
    }
  }
}`;

export const ACTIVATE_OTA_RATEPLAN = `mutation ($id: String!) {
  activate_ota_rateplan(_id: $id){
    message
  }
}`;

export const ADD_NEW_ROOMTYPE_OTA = `mutation ($id: String!, $ota: String!, $roomtypeCode: String!) {
  add_new_roomtype_ota(_id: $id, ota: $ota, roomtype_code: $roomtypeCode){
    message
  }
}`;

export const ADD_NEW_ROOMTYPE_OTA_RATEPLAN = `mutation ($id: String!, $ota: String!, $rateCode: String!, $currencyCode: String, $rateType: String, $name: String, $description: String, $mealPlanId: String) {
  add_new_roomtype_ota_rateplan(_id: $id, ota: $ota, rate_code: $rateCode, currency_code: $currencyCode, rate_type: $rateType, name: $name, description: $description, meal_plan_id: $mealPlanId){
    message
  }
}`;

export const ADD_NEW_OTA = `mutation ($ota: String!, $bearerToken: String, $hotelCode: String!, $username: String, $password: String, $apiKey: String) {
  add_new_ota(ota: $ota, bearer_token: $bearerToken, hotel_code: $hotelCode, username: $username, password: $password, api_key: $apiKey){
    message
  }
}`;