export const ADD_NEW_HIGHLIGHT = `mutation ($name: String!, $icon: String!) {
    add_new_highlight(name: $name, icon: $icon){
      message
    }
  }
`;

export const ACTIVATE_HIGHLIGHT = `mutation ($id : String!) {
    activate_highlight(_id: $id){
      message
    }
  }
`;

export const LIST_ALL_HIGHLIGHTS = `query ($pageSize: Int, $pageNumber: Int) {
    list_all_highlights(page_size: $pageSize, page_number: $pageNumber){
      message
      data {
        _id
        name
        icon
        is_active
      }
      hasMore
    }
  }
`;

export const ADD_NEW_FACILITY = `mutation ($name: String!, $icon: String!) {
    add_new_facility(name: $name, icon: $icon){
      message
    }
  }
`;


export const LIST_ALL_FACILITIES = `query ($pageSize: Int, $pageNumber: Int) {
    list_all_facilities(page_size: $pageSize, page_number: $pageNumber){
      message
      data {
        _id
        name
        icon
        is_active
      }
      hasMore
    }
  }
`;

export const LIST_ALL_RATES = `query ($pageSize: Int, $pageNumber: Int, $rateType: String, $roomtypeId: String, $startDate: Int, $endDate: Int ) {
  list_all_rates(page_size: $pageSize, page_number: $pageNumber, rate_type: $rateType, roomtype_id: $roomtypeId, start_date: $startDate, end_date: $endDate){
    message
    data {
      _id
      current_rate
      roomtype {
        name
        description
      }
    }
    hasMore
  }
}`;

export const ADD_NEW_RATE = `mutation ($roomId: String, $roomtypeId: String, $normalRate: Float, $offers: [add_new_rate_offers]) {
  add_new_rate(room_id: $roomId, roomtype_id: $roomtypeId, normal_rate: $normalRate, offers: $offers){
    message
  }
}`;

export const UPLOAD_FILE = `mutation ($file: String!, $fileType: String) {
  upload_file(file: $file, file_type: $fileType){
    message
    url
  }
}`;

export const CHANGE_HOTEL_PASSWORD = `mutation ($password: String!) {
  change_hotel_password(password: $password){
    message
  }
}`;